exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-henkilosto-js": () => import("./../../../src/pages/henkilosto.js" /* webpackChunkName: "component---src-pages-henkilosto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kohteet-js": () => import("./../../../src/pages/kohteet.js" /* webpackChunkName: "component---src-pages-kohteet-js" */),
  "component---src-pages-laurimehto-js": () => import("./../../../src/pages/laurimehto.js" /* webpackChunkName: "component---src-pages-laurimehto-js" */),
  "component---src-pages-palvelut-js": () => import("./../../../src/pages/palvelut.js" /* webpackChunkName: "component---src-pages-palvelut-js" */),
  "component---src-pages-ruusu-js": () => import("./../../../src/pages/ruusu.js" /* webpackChunkName: "component---src-pages-ruusu-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */)
}

